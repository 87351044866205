html,
body {
  height: 100%;
  word-wrap: break-word;
}

#root {
  min-height: 100%;
  background-color: #e2e2e2;
}

#root>div {
  min-height: 100%;
}

.hm-400 {
  max-height: 400px;
}

.hm-400 canvas {
  max-height: 400px;
}

.overflow-y-auto {
  overflow-y: scroll;
  overflow-x: auto;
}

.required {
  margin-left: 5px;
  background-color: red;
  padding: 2px;
  color: white;
  font-size: 80%;
}

.prewrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
}

/*
.dep {
  margin-left: 10px;
}

.target {
  border: solid 1px #000;
}

.targetParentCategory {
  border: solid 1px red;
}

.targetCategory {
  border: solid 1px red;
*/


header {
  height: 80px;
  line-height: 80px;
  background-color: #fff;
}

header .header-logo {
  background: #000;
  text-align: center;
}

header .header-logo img {
  max-width: 160px;
  width: 100%;
}

header .logout {
  background-color: #000;
  color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  border: none;
}

header .logout:hover {
  background-color: #333;
  color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  border: none;
}

#sidebar {
  max-width: 300px;
  min-height: 100vh;
  background: #000;
  padding-right: 0;
}

#sidebar .header {
  height: 80px;
  background: #000;
  text-align: center;
  border-bottom: 1px solid #fff;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

#sidebar .header img {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
}

#sidebar .nav-link {
  height: 90px;
  border-bottom: 1px solid #fff;
  align-items: center;
  display: flex;
  color: #fff;
}

#sidebar .nav-link.active {
  background-color: #69c0bc;
  color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

#sidebar .nav-link a {
  color: #fff;
}

#sidebar .nav-link .nav-icon {
  min-width: 55px;
  min-height: 55px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#sidebar .nav-link .nav-icon {
  margin-right: 15px;
}

#sidebar .nav-link .nav-icon.home {
  background-image: url("../../public/img/icon_home.png");
}

#sidebar .nav-link .nav-icon.admin {
  background-image: url("../../public/img/icon_admin.png");
}

#sidebar .nav-link .nav-icon.user {
  background-image: url("../../public/img/icon_user.png");
}

#sidebar .nav-link .nav-icon.license {
  background-image: url("../../public/img/icon_license.png");
}

#sidebar .nav-link .nav-icon.category {
  background-image: url("../../public/img/icon_category.png");
}

#sidebar .nav-link .nav-icon.movie {
  background-image: url("../../public/img/icon_movie.png");
}

#sidebar .nav-link .nav-icon.notice {
  background-image: url("../../public/img/icon_notice.png");
}

#sidebar .nav-link.active .nav-icon.home {
  background-image: url("../../public/img/icon_home_active.png");
}

#sidebar .nav-link.active .nav-icon.admin {
  background-image: url("../../public/img/icon_admin_active.png");
}

#sidebar .nav-link.active .nav-icon.user {
  background-image: url("../../public/img/icon_user_active.png");
}

#sidebar .nav-link.active .nav-icon.license {
  background-image: url("../../public/img/icon_license_active.png");
}

#sidebar .nav-link.active .nav-icon.category {
  background-image: url("../../public/img/icon_category_active.png");
}

#sidebar .nav-link.active .nav-icon.movie {
  background-image: url("../../public/img/icon_movie_active.png");
}

#sidebar .nav-link.active .nav-icon.notice {
  background-image: url("../../public/img/icon_notice_active.png");
}

#main {
  height: 100%;
  padding-left: 0;
}

#main .header {
  height: 80px;
  background-color: #fff;
  text-align: right;
  padding-right: 20px;
  line-height: 80px;
}

#main .header {
  height: 80px;
  background-color: #fff;
}

#main .header .logout {
  background-color: #0c423c;
  color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  border: none;
}

#contents {
  min-height: 100%;
  padding-left: 0;
  background-color: #fff;
  margin: 30px 40px;
  padding: 30px;
}

#contents .header .logout:hover {
  background-color: #177c70;
  color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  border: none;
}

#contents .btn-primary,
.modal-body .btn-primary {
  background-color: #69c0bc;
  border: none;
}

.user-table th,
.user-table td {
  height: 50px;
  vertical-align: middle;
}

.category-dep .dep {
  padding: 2px 5px;
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #5b7860;
}

.category-dep .dep.dep-0 {
  background-color: #043b0d;
}

.category-dep .dep.dep-1 {
  background-color: #1c722a;
}

.category-dep .dep.dep-2 {
  background-color: #44b257;
}

.category-dep .dep a {
  color: #fff;
}

.category-dep .cat {
  border: 3px solid #f00;
  box-sizing: border-box;
}

.category-tree .dep {
  margin-top: 5px;
}

.category-tree .targetCategory {
  border: 2px solid #f00;
  background-color: rgb(231, 225, 225);
  padding: 2px;
  display: inline-block;
}

.movie-table th {
  white-space: nowrap;
  vertical-align: middle;
}

.movie-table td {
  vertical-align: middle;
}

.movie-table .thumb img {
  max-width: 300px;
}

.movie-table .moview .player {
  height: 100%;
}

.movie-table .player {
  width: 480px;
  height: 270px;
}

.movie-table .react-player__preview {
  width: 480px;
  height: 270px;
}

h1.h4 {
  margin-bottom: 25px;
  padding-bottom: 5px;
  font-weight: 400;
}

.progress-bar-area {
  display: flex;
  justify-content: center;
  align-items: center
}

.progress-bar-area .progress {
  width: 80%
}

#auth-signin .card-header {
  background: #0c423c;
  text-align: center;
}

#auth-signin .card-header img {
  margin: 15px 0;
  max-width: 160px;
  width: 100%;
}