#auth-signup {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0;
}

#auth-signup #contents {
  width: 100%;
  max-width: 480px;
  padding: 15px;
  margin: auto;
}

